import React from "react"

import AxelrodImage from "../../images/axelrod.png"
import TagmapImage from "../../images/tagmap.png"
import HierarchyImage from "../../images/hierarchy.png"
import PortfolioCard from "../../components/PortfolioCard"

const Hobby = () => (
  <div id="portfolio-hobby">
    <div id="portfolio-description"></div>
    <div id="portfolio-cards-hobby" className="portfolio__group">
      <PortfolioCard
        title="Tagmap"
        description="An application used to analyze PLC tags and their dependency chains on a manufacturing network"
        img={TagmapImage}
        link="https://github.com/erik-sn/tagmap"
      />
      <PortfolioCard
        title="Hierarchy"
        description="An application used to integrate interal APIs to make custom modules and dashboards in a manufacturing organization"
        img={HierarchyImage}
        link="https://github.com/erik-sn/hierarchy"
      />
      <PortfolioCard
        title="Axelrod Web & API"
        description="A Django/DRF API & react UI that wraps the axelrod python library. Axelrod is a package that facilitates research around the iterated Prisoner's Dilema."
        img={AxelrodImage}
        link="https://github.com/Axelrod-Python"
      />
    </div>
  </div>
)

export default Hobby
